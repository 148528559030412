
var target;
var control;
import Swiper from 'Swiper';
import 'swiper/css/swiper.min.css';

$(document).ready(function () {
  initSwiperPet();
});

function viewportIsMobile(width) {
  return (width < 1025);
}
$(function () {
  $('.tab-wrapper').each(function (index, value) {
    $(this).children().closest('.tab').find('.tab-name').first().trigger('click');
  });
});

function activeTab(obj) {
  $(obj).closest('.tab').find('li').removeClass('active');
  $(obj).addClass('active');
  var id = $(obj).find('a').attr('href');
  $(obj).closest('.tab-wrapper').find('.tab-item').hide();
  $(id).show();
  $(obj).closest('.tab-wrapper').find('.tab-wrapper').each(function (index, value) {
    $(this).children().closest('.tab').find('.tab-name').first().trigger('click');
  });
}

$('.tab li').click(function () {
  if ($(this).is(':last-child') && $(this).parents('.pet-container').length > 0) {
    return true;
  } else {
    activeTab(this);
    return false;
  }
});


window.initSwiperPet = initSwiperPet;
function initSwiperPet() {
  if ($('.swiperNews').length > 0) {
    var galleryTop = new Swiper('.swiperNews', {
      slidesPerView: 1,
      // scrollbar: {
      //   el: '.swiper-scrollbar',
      // },
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
      lazy: true,
      preloadImages: false,
      lazyLoading: true,
      // thumbs: {
      //   swiper: galleryThumbs,
      // },
      // navigation: {
      //   nextEl: '.swiper-button-next',
      //   prevEl: '.swiper-button-prev',
      // },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      // speed: 0,
      autoplay: {
        delay: 6000,
        // waitForTransition: false,
        // disableOnInteraction: false,
      },
      // slidesPerView: 'auto',
      // autoplayDisableOnInteraction: false,
      // breakpoints: {
      //   375: {
      //     slidesPerView: 1,
      //     spaceBetween: 50
      //   },
      //   768: {
      //     slidesPerView: 1,
      //     spaceBetween: 50
      //   },
      //   1024: {
      //     slidesPerView: 1,
      //     spaceBetween: 50
      //   },
      // }
    });
  }
  if ($('.swiperHotNews').length > 0 && viewportIsMobile($(window).outerWidth())) {
    var galleryTop = new Swiper('.swiperHotNews', {
      slidesPerView: 1,
      spaceBetween: 50,
      lazy: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      lazy: true,
      preloadImages: false,
      lazyLoading: true,
    });
  }
  //   if ($('.gallery-thumbs').length > 0) {
  //     var galleryCharThumbs = new Swiper('.gallery-thumbs', {
  //         freeMode: true,
  //         watchSlidesVisibility: true,
  //         watchSlidesProgress: true,
  //         navigation: {
  //             nextEl: '.swiper-next-thumbs',
  //             prevEl: '.swiper-prev-thumbs',
  //         },
  //         touchRatio: 0,
  //         direction: 'vertical',
  //         slidesPerView: 7,
  //         observer: true,
  //         observeParents: true,
  //     });
  //     var galleryCharTop = new Swiper('.gallery-top', {
  //         slidesPerView: 1,
  //         direction: 'horizontal',
  //         navigation: {
  //             nextEl: '.swiper-button-next',
  //             prevEl: '.swiper-button-prev',
  //         },
  //         thumbs: {
  //             swiper: galleryCharThumbs
  //         },
  //         autoplay: {
  //             delay: 5000,
  //             waitForTransition: false,
  //             disableOnInteraction: false,
  //         },
  //     });
  //     galleryCharTop.on('transitionEnd', function () {
  //         $(".char-intro").removeClass("active");
  //         $(".char-item").removeClass("active");
  //         $(".thumbnail-char span").removeClass("active");
  //         $(".char-description").removeClass("active");
  //         $(".char-skill").removeClass("active");
  //         $(".thumbnail-char").removeClass("active");
  //         var target = galleryCharTop.realIndex + 1;
  //         $(".char-intro:nth-child(" + target + ")").addClass("active");
  //         $(".char .active .char-item").first().addClass("active");
  //         if ($(".char .active .thumbnail-char .item1").hasClass("off")) {
  //             $(".char .active .thumbnail-char .item2").addClass("active");
  //         } else {
  //             $(".char .active .thumbnail-char .item1").addClass("active");
  //         }
  //         $(".char .active .char-description").addClass("active");
  //         $(".char .active .char-skill").addClass("active");
  //         $(".char .active .thumbnail-char").addClass("active");
  //     });
  //     $(".thumbnail-char span").hover(function (e) {
  //         $(".char-item").removeClass("active");
  //         $(".thumbnail-char span").removeClass("active");
  //         var target = e.currentTarget.getAttribute("data-char-item");
  //         $(e.currentTarget).addClass("active");
  //         $("." + target + "").addClass("active");
  //     });
  // }

}

var swiper = new Swiper('.swiper-frame-5', {
  effect: 'coverflow',
  grabCursor: true,
  centeredSlides: true,
  slidesPerView: 'auto',
  navigation: {
    nextEl: '.swiper-frame-5 .swiper-button-next',
    prevEl: '.swiper-frame-5 .swiper-button-prev',
  },
  coverflowEffect: {
    rotate: 0,
    stretch: 0,
    depth: 250,
    modifier: 1,
    slideShadows: false,
  },
  autoplay: {
    delay: 6000,
  },
  lazy: true,
  preloadImages: false,
  lazyLoading: true,
  pagination: {
    el: '.swiper-frame-5 .swiper-pagination',
    clickable: true
  },
});

if ($('.classSwiper .swiperChar').length > 0) {
  var bulletClass = [];
  var nameClass = [];
  for (var i = 0; i < $('.classSwiper .swiperChar').length; i++) {
    var img = $('.classSwiper .swiperChar').eq(i).attr('data-bullet');
    var text = $('.classSwiper .swiperChar').eq(i).attr('data-name');
    bulletClass.push(img);
    nameClass.push(text);
  }
  var classSwiper = new Swiper('.classSwiper', {
    autoplay: {
      delay: 6000,
    },
    lazy: true,
    preloadImages: false,
    lazyLoading: true,
    pagination: {
      el: '.classSwiper .swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        // return '<span class="' + className + '">' + (index + 1) + '</span>';
        return `<a href="javascript:void(0)" class="` + className + `">
        <img data-src="` + (bulletClass[index]) + `" class=" lazyload" >
        <span>` + (nameClass[index]) + `</span>
        </a>`;
      }
    },
  });
}

if ($('.petSwiper .swiper-slide').length > 0) {
  var nameClass = [];
  var imgClass = [];
  for (var i = 0; i < $('.petSwiper .swiper-slide').length; i++) {
    var text = $('.petSwiper .swiper-slide').eq(i).attr('data-name');
    var img = $('.petSwiper .swiper-slide').eq(i).attr('data-img');
    imgClass.push(img);
    nameClass.push(text);
  }
  var petSwiper = new Swiper('.petSwiper', {
    autoplay: {
      delay: 6000,
    },
    lazy: true,
    preloadImages: false,
    lazyLoading: true,
    pagination: {
      el: '.petSwiper .swiper-pagination',
      clickable: true,
      renderBullet: function (index, className) {
        // return '<span class="' + className + '">' + (index + 1) + '</span>';
        return `<a href="javascript:void(0)" class="btn btn-pet ` + className + ' ' + (imgClass[index]) + `">
        <span class="">` + (nameClass[index]) + `</span>
        </a>`;
      }
    },
  });
}



if (jQuery('.article__detail .tab__detail').length > 0 && jQuery('#tabHeader').length > 0) {
  $('.tab__detail').hide();
  jQuery('#tabHeader li').eq(0).find('a').addClass('active');
  var curId = jQuery('#tabHeader li').eq(0).find('a').data('href');
  jQuery('.article__detail').find(curId).show();
  jQuery('#tabHeader > li > a').click(function () {
    jQuery('.article__detail .tab__detail').hide();
    jQuery('#tabHeader > li > a').removeClass('active');
    jQuery(this).addClass('active');
    var curId = jQuery(this).data('href');
    jQuery('.article__detail').find(curId).show();
    return false;
  });
} else {
  jQuery('.tab__detail').show();
}

