import "lazysizes";
import Scrollbar from 'smooth-scrollbar';
import BezierEasing from "bezier-easing";

// SCROLL
// -- Init
var scaleratio = $(window).outerHeight() / 1000;

if ($(".scrollContent").length > 0) {
  if (!viewportIsMobile($("body").outerWidth())) {
    var vdamping = 0.1;
    let scrollbars = {};
    scrollbars['root'] = Scrollbar.init($(".scrollContent")[0], {
      alwaysShowTracks: true,
      damping: vdamping
    });
    let frameSwipe = {
      total: 3,
      frames: ['.section--1', '.section--2', '.section--3'],
      navigation: '.nav-left li',
      index: 0,
      isEndAnimate: true,
      animateDelay: 400,
      animateEndDelay: 400,
    }

    jQuery(document).ready(function () {
      $("#scrollTop").on("click", function (e) {
        scrollbars['root'].scrollTo(0, 0, 1000, {
          easing: (p) => {
            var easing = BezierEasing(0.86, 0, 0.07, 1);
            return easing(p);
          },
        });
        return false;
      });
      scrollbars['root'].addListener(function (status) {
        if ($('.manhhoa').length > 0) {
          if (scrollbars['root'].offset.y == 0) {
            $('.guide__fixed-background').css("top", 245);
          } else {
            $('.guide__fixed-background').css("top", "0");
          }
        }
      });
    });

    $("#floating").appendTo(".section--0");
  }
}

if ($(".scrollArticle").length > 0) {
  for (var i = 0; i < $(".scrollArticle").length; i++) {
    scrollbars['article'] = Scrollbar.init($(".scrollArticle")[i], {
      alwaysShowTracks: true
    })
  }
}

$('.sub-nav-mb .scrollLink').click(function (e) {
  var hrefMob = $(this).attr('data-scroll-link');
  if (isMobile.any() || window.innerWidth < window.innerHeight) {
    event.preventDefault();
    var elementIndex = $(hrefMob);
    if (elementIndex.length) {
      $('html, body').stop().animate({
        scrollTop: elementIndex.offset().top
      }, 1000);
    }
  }
});

function viewportIsMobile(width) {
  return (width < 1025);
}

var isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Desktop: function () {
    return navigator.userAgent.match(/Windows NT/i);
  },
  Windows: function () {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any: function () {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};

window.Scrollbar = Scrollbar;